// 道路保洁 出勤记录
<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs"
            key="parent">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <template v-if="currentTabIndex===0">
              <MyTreeSelect :value="gridName"
                            placeholder="围栏名称"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="gridTreeArr"
                            search
                            @onSelectChange="onChangeGridName">
              </MyTreeSelect>
              <!-- <Input v-model="planName"
                     placeholder="计划名称"
                     maxlength="30"
                     clearable
                     v-stringLimit
                     class="m-r-10"
                     style="width:160px" /> -->
              <MyTreeSelect :value="name"
                            placeholder="姓名"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="personTreeArr"
                            search
                            @onSelectChange="onChangeName">
              </MyTreeSelect>
            </template>
            <template v-if="currentTabIndex===1">
              <MyTreeSelect :value="name"
                            placeholder="姓名"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="personTreeArr"
                            search
                            checked
                            @onCheckChange="onChangeName">
              </MyTreeSelect>
            </template>
            <template v-if="currentTabIndex===2">
              <Input v-model="fieldName"
                     placeholder="顶岗人"
                     maxlength="30"
                     clearable
                     v-stringLimit
                     class="m-r-10"
                     style="width:160px" />
              <Input v-model="byFieldName"
                     placeholder="被顶岗人"
                     clearable
                     class="m-r-10"
                     style="width:160px" />
            </template>
            <template v-if="currentTabIndex===3">
              <MyTreeSelect :value="name"
                            placeholder="姓名"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="personTreeArr"
                            search
                            checked
                            @onCheckChange="onChangeName">
              </MyTreeSelect>
            </template>
            <DatePicker type="daterange"
                        :value="date"
                        :start-date="new Date()"
                        placeholder="请选择时间范围"
                        @on-change="onChangeDate"
                        style="width:260px"></DatePicker>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/roadClean-attendance/query'])"
                    :ghost="buttonIndex == 0 ? false : true"
                    @click.stop="onClickLastWeek">上周</Button>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/roadClean-attendance/query'])"
                    :ghost="buttonIndex == 1 ? false : true"
                    @click.stop="onClickCurrentWeek">本周</Button>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/roadClean-attendance/query'])"
                    :ghost="buttonIndex == 2 ? false : true"
                    @click.stop="onClickLastMonth">上月</Button>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/roadClean-attendance/query'])"
                    :ghost="buttonIndex == 3 ? false : true"
                    @click.stop="onClickCurrentMonth">本月</Button>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/roadClean-attendance/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/roadClean-attendance/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
      <template v-if="currentAttendanceTabIndex!=2">
        <div class="flex"
             style="margin-left:17px">
          <Dropdown v-if="checkPermi(['/admin/roadClean-attendance/export'])">
            <Button class="m-r-10">
              <Icon custom="i-icon icon-shangchuan"
                    size="16"></Icon>
              导出
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="now">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('now')">
                  <Button>
                    导出本页
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="all">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('all')">
                  <Button>
                    导出全部
                  </Button>
                </ExportTemplate>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </template>
    </div>
    <div class="content">
      <template v-if="currentTabIndex===0">
        <Tabs v-model="currentAttendanceTabIndex"
              class="tabs"
              key="child1">
          <TabPane v-for="item in attendanceTabArr"
                   :key="item.name"
                   :label="item.name"
                   :disabled="item.disable"></TabPane>
          <div class="flex"
               slot="extra">
            <span class="m-l-10">
              <Icon custom="i-icon icon-zhengque"
                    size="14"
                    color="#0eb36e"
                    class="m-r-4"></Icon>
              正常
            </span>
            <span class="m-l-10">
              <Icon custom="i-icon icon-yi"
                    size="22"
                    color="#FD2323"
                    class="m-r-4"></Icon>
              异常
            </span>
            <span class="m-l-10">
              <Icon custom="i-icon icon-xiu"
                    size="22"
                    color="#0eb36e"
                    class="m-r-4"></Icon>
              休假
            </span>
            <span class="m-l-10">
              <Icon custom="i-icon icon-jia"
                    size="22"
                    color="#0079FE"
                    class="m-r-4"></Icon>
              请假
            </span>
            <span class="m-l-10">
              / 无排班
            </span>
          </div>
        </Tabs>
      </template>
      <template v-if="currentTabIndex===1">
        <Tabs v-model="currentLeaveTabIndex"
              class="tabs"
              key="child2">
          <TabPane v-for="item in leaveTabArr"
                   :key="item.name"
                   :label="item.name"></TabPane>
        </Tabs>
      </template>
      <template v-if="currentTabIndex===3">
        <Tabs v-model="currentOvertimeTabIndex"
              class="tabs"
              key="child3">
          <TabPane v-for="item in overtimeTabArr"
                   :key="item.name"
                   :label="item.name"></TabPane>
        </Tabs>
      </template>
      <div class="table">
        <Table ref="table"
               :width="tableWidth"
               :height="tableHeight"
               :columns="getColumns"
               :data="getData"
               border
               stripe
               :loading="tableLoading"
               :row-class-name="rowClassName"
               @on-selection-change="onChangeSelect">
          <template slot-scope="{ row }"
                    slot="operation">
            <template v-if="currentAttendanceTabIndex == 2">
              <!-- 1 已回复 0 未回复 -->
              <Button @click.stop="onClickRecovery(row)"
                      class="tableOperaBtn"
                      type="text"
                      v-if="row.status == 0">恢复</Button>
              <span v-if="row.status == 1">已恢复</span>
            </template>
          </template>
        </Table>
        <div class="flex a-center j-between">
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
          <!-- <Button>导出</Button> -->
        </div>
      </div>
    </div>

    <!-- 完成度设置 -->
    <GridFinishDegreeModal v-model="gridCompleteVisible"
                           :type.sync="gridCompleteType"
                           @onClickConfirm="getCompleteness"></GridFinishDegreeModal>

    <!-- 打卡纠正 -->
    <RoadCleanCorrectModal v-model="roadCleanCorrectVisible"
                           :data.sync="roadCleanCorrectData"></RoadCleanCorrectModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getCurrentMonth, getLastMonth, getCurrentWeek, getLastWeek } from '@/utils/dateRangUtil'
import MyTreeSelect from '@/components/common/MyTreeSelect';
import ExportTemplate from '@/components/common/ExportTemplate'
import GridFinishDegreeModal from '@/components/product/admin/modal/work/GridFinishDegreeModal'
import RoadCleanCorrectModal from '@/components/product/admin/modal/work/RoadCleanCorrectModal'
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    MyTreeSelect, ExportTemplate, RoadCleanCorrectModal, GridFinishDegreeModal
  },
  data () {
    return {
      currentTabIndex: 0,
      buttonIndex: null,
      tabArr: [
        {
          name: '考勤记录'
        },
        // {
        //   name: '请假记录'
        // },
        // {
        //   name: '顶岗记录'
        // },
        // {
        //   name: '加班记录'
        // },
      ],
      gridId: '',
      gridName: '',
      planName: '',
      nameId: '',
      name: '',
      fieldName: '',
      byFieldName: '',
      date: [],
      currentAttendanceTabIndex: 0,
      attendanceTabArr: [
        {
          name: '出勤汇总',
          disable: false
        },
        {
          name: '出勤明细',
          disable: true
        },
        {
          name: '纠正记录',
          disable: false
        },
      ],
      currentLeaveTabIndex: 0,
      leaveTabArr: [
        {
          name: '请假汇总'
        },
        {
          name: '请假明细'
        },
      ],
      currentOvertimeTabIndex: 0,
      overtimeTabArr: [
        {
          name: '加班汇总'
        },
        {
          name: '加班明细'
        },
      ],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableHeight: 0,
      tableLoading: false,
      attendanceSummaryData: [],
      columnsAttendanceSummary: [
        {
          title: '序号',
          width: 45,
          fixed: 'left',
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'employeeName',
          width: 70,
          fixed: 'left',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickAttendancePersonName(row)
                }
              }
            }, row.employeeName);
          }
        },
        {
          title: '所属机构',
          key: 'deptName',
          width: 90,
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '电子围栏',
          key: 'gridName',
          width: 90,
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '计划考勤天数',
          key: 'planDays',
          width: 110,
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '计划考勤次数',
          key: 'planNumbers',
          width: 110,
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '正常',
          key: 'normality',
          width: 70,
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '迟到',
          key: 'laterIn',
          width: 70,
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '早退',
          key: 'leaveEarly',
          width: 70,
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '旷工',
          key: 'noWork',
          width: 70,
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缺卡',
          key: 'lackCard',
          width: 70,
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
      ],
      attendanceDetailData: [],
      columnsAttendanceDetail: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'employeeName',
          tooltip: true,
          width: 70,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptName',
          tooltip: true,
          width: 90,
          align: 'center'
        },
        {
          title: '日期',
          key: 'punchCardDate',
          tooltip: true,
          width: 90,
          align: 'center'
        },
        // {
        //   title: '计划名称',
        //   key: 'planName',
        //   tooltip: true,
        //   width: 90,
        //   align: 'center'
        // },
        // {
        //   title: '日期',
        //   key: 'date',
        //   tooltip: true,
        //   width: 90,
        //   align: 'center'
        // },
        // {
        //   title: '班组',
        //   key: 'groupName',
        //   tooltip: true,
        //   width: 90,
        //   align: 'center'
        // },
        // {
        //   title: '上班考勤结果(1)',
        //   key: 'sbdkjg1',
        //   tooltip: true,
        //   width: 110,
        //   align: 'center'
        // },
        // {
        //   title: '在岗情况',
        //   key: 'zgqk1',
        //   tooltip: true,
        //   width: 110,
        //   align: 'center'
        // },
        // {
        //   title: '下班考勤结果(1)',
        //   key: 'xbdkjg1',
        //   tooltip: true,
        //   width: 110,
        //   align: 'center'
        // },
        // {
        //   title: '上班考勤结果(2)',
        //   key: 'sbdkjg2',
        //   tooltip: true,
        //   width: 110,
        //   align: 'center'
        // },
        // {
        //   title: '在岗情况',
        //   key: 'zgqk2',
        //   tooltip: true,
        //   width: 110,
        //   align: 'center'
        // },
        // {
        //   title: '下班考勤结果(2)',
        //   key: 'xbdkjg2',
        //   tooltip: true,
        //   width: 110,
        //   align: 'center'
        // },
        // {
        //   title: '上班考勤结果(3)',
        //   key: 'sbdkjg3',
        //   tooltip: true,
        //   width: 110,
        //   align: 'center'
        // },
        // {
        //   title: '在岗情况',
        //   key: 'zgqk3',
        //   tooltip: true,
        //   width: 110,
        //   align: 'center'
        // },
        // {
        //   title: '下班考勤结果(3)',
        //   key: 'xbdkjg3',
        //   tooltip: true,
        //   width: 110,
        //   align: 'center'
        // },
        // {
        //   title: '上班考勤结果(4)',
        //   key: 'sbdkjg4',
        //   tooltip: true,
        //   width: 110,
        //   align: 'center'
        // },
        // {
        //   title: '在岗情况',
        //   key: 'zgqk4',
        //   tooltip: true,
        //   width: 110,
        //   align: 'center'
        // },
        // {
        //   title: '下班考勤结果(4)',
        //   key: 'xbdkjg4',
        //   tooltip: true,
        //   width: 110,
        //   align: 'center'
        // },
        // {
        //   title: '实上班时长',
        //   key: 'sjsbsc',
        //   tooltip: true,
        //   width: 110,
        //   align: 'center'
        // },
        // {
        //   title: '圈数',
        //   key: 'cycleCount',
        //   tooltip: true,
        //   width: 40,
        //   align: 'center'
        // },
        // {
        //   title: '步数(步)',
        //   key: 'stepCount',
        //   tooltip: true,
        //   width: 70,
        //   align: 'center'
        // },
        // {
        //   title: '里程(km)',
        //   key: 'km',
        //   tooltip: true,
        //   width: 70,
        //   align: 'center'
        // }
      ],
      correctData: [],
      columnsCorrect: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'employeeName',
          tooltip: true,
          width: 70,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'departmentName',
          tooltip: true,
          width: 90,
          align: 'center'
        },
        {
          title: '网格名称',
          key: 'gridName',
          tooltip: true,
          width: 90,
          align: 'center'
        },
        // {
        //   title: '计划名称',
        //   key: 'planName',
        //   tooltip: true,
        //   width: 90,
        //   align: 'center'
        // },
        {
          title: '日期',
          key: 'punchCardDate',
          tooltip: true,
          width: 90,
          align: 'center'
        },
        {
          title: '上班纠正前打卡结果',
          key: 'sbdkjgJzq',
          tooltip: true,
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.afterCorrectGotoWork) {
              return h('span', row.afterCorrectGotoWorkStatus + "(" + row.afterCorrectGotoWork + ")")
            } else {
              return h('span', row.afterCorrectGotoWorkStatus)
            }
          }
        },
        {
          title: '上班纠正后打卡结果',
          key: 'sbdkjgJzh',
          tooltip: true,
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.beforeCorrectGotoWork) {
              return h('span', row.beforeCorrectGotoWorkStatus + "(" + row.beforeCorrectGotoWork + ")")
            } else {
              return h('span', row.beforeCorrectGotoWorkStatus)
            }
          }
        },
        {
          title: '下班纠正前打卡结果',
          key: 'xbdkjgJzq',
          tooltip: true,
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.afterCorrectComeWorkStatus + "(" + row.afterCorrectComeWork + ")")
          }
        },
        {
          title: '下班纠正后打卡结果',
          key: 'xbdkjgJzh',
          tooltip: true,
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.beforeCorrectComeWorkStatus + "(" + row.beforeCorrectComeWork + ")")
          }
        },
        {
          title: '纠正人',
          key: 'correctPerson',
          tooltip: true,
          width: 50,
          align: 'center'
        },
        {
          title: '纠正理由',
          key: 'correctReason',
          tooltip: true,
          width: 90,
          align: 'center'
        },
        {
          title: '纠正时间',
          key: 'correctTime',
          tooltip: true,
          width: 150,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 80,
          align: 'center'
        }
      ],
      columnsLeaveSummary: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'managePhone',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'managePhone',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '累计请假次数',
          key: 'managePhone',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '累计请假工时',
          key: 'managePhone',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
      ],
      columnsLeaveDetail: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计请假次数',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计请假工时',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsField: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '顶岗人',
          key: 'manageName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '被顶岗人',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '顶岗日期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '顶岗时段',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作时间',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作人',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsOvertimeSummary: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'manageName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '计划名称',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计加班次数',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计加班时长',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsOvertimeDetail: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'manageName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '计划名称',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计加班次数',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计加班时长',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
      ],
      data: [],
      selectArr: [],
      // 完成度
      gridCompleteVisible: false,
      gridCompleteType: null,
      mileage: '',  // 里程
      path: '',      // 路径
      // 打卡纠正
      roadCleanCorrectVisible: false,
      roadCleanCorrectData: {}
    };
  },
  watch: {
    currentTabIndex () {
      this.resetSearchData()
      this.onClickSearch()
    },
    currentAttendanceTabIndex (newVal) {
      if (newVal == 0) {
        this.name = ''
        this.nameId = ''
      }
      this.onClickSearch()
    },
  },
  computed: {
    ...mapGetters({
      'personTreeArr': 'getPersonTreeList',
      'gridTreeArr': 'getEnclosureTreeList',
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentAttendanceTabIndex) {
            case 0:
              columns = this.columnsAttendanceSummary
              break;
            case 1:
              columns = this.columnsAttendanceDetail
              break;
            case 2:
              columns = this.columnsCorrect
              break;
            default:
              break;
          }
          break;
        case 1:
          switch (this.currentLeaveTabIndex) {
            case 0:
              columns = this.columnsLeaveSummary
              break;
            case 1:
              columns = this.columnsLeaveDetail
              break;
            default:
              break;
          }
          break;
        case 2:
          columns = this.columnsField
          break;
        case 3:
          switch (this.currentOvertimeTabIndex) {
            case 0:
              columns = this.columnsOvertimeSummary
              break;
            case 1:
              columns = this.columnsOvertimeDetail
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    },
    getExportPath () {
      let path = ''
      switch (this.currentAttendanceTabIndex) {
        case 0:
          path = this.$http.exportCarCostOil
          break;
        case 1:
          path = this.$http.exportCarCostRepair
          break;
        default:
          break;
      }
      return path
    },
    getData () {
      let data = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentAttendanceTabIndex) {
            case 0:
              data = this.attendanceSummaryData
              break;
            case 1:
              data = this.attendanceDetailData
              break;
            case 2:
              data = this.correctData
              break;
            default:
              break;
          }
          break;
        case 1:

          break;
        case 2:

          break;
        default:
          break;
      }
      return data
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updatePersonTreeList',
      'updateEnclosureTreeList'
    ]),
    init () {
      this.tableWidth = window.innerWidth - 240
      this.tableHeight = window.innerHeight - 370
      window.onresize = () => {
        this.tableWidth = window.innerWidth - 240
        this.tableHeight = window.innerHeight - 370
      }
      this.updatePersonTreeList()
      this.updateEnclosureTreeList()
      this.onClickCurrentMonth()
      this.getCompleteness()
    },
    // 获取完成度
    getCompleteness () {
      this.$store.dispatch('getTimeCompleteDetail', 2).then(res => {
        this.mileage = res.kmOkThreshold
        this.path = res.routeOkThreshold
        this.motion = res.motionOkThreshold
      })
    },
    // 设置完成度
    onClickComplete () {
      this.gridCompleteVisible = true
      this.gridCompleteType = 2
    },
    rowClassName (row, index) {
      if (this.currentAttendanceTabIndex == 2) {
        if (row.status == 1) {
          return 'table-recovery-color'
        }
      }
    },
    // 选择网格
    onChangeGridName (section) {
      if (section.type !== 'depart') {
        this.gridName = section.name
        this.gridNameId = section.id
      }
    },
    // 选择人员
    onChangeName (section) {
      if (section.type === 'user') {
        this.nameId = section.id
        this.name = section.name
      }
    },
    onChangeDate (date) {
      this.date = date
      this.buttonIndex = null
    },
    // 本周
    onClickCurrentWeek () {
      this.date = getCurrentWeek()
      this.onClickSearch()
      this.buttonIndex = 1
    },
    // 上周
    onClickLastWeek () {
      this.date = getLastWeek()
      this.onClickSearch()
      this.buttonIndex = 0
    },
    // 本月
    onClickCurrentMonth () {
      this.date = getCurrentMonth()
      this.onClickSearch()
      this.buttonIndex = 3
    },
    // 上月
    onClickLastMonth () {
      this.date = getLastMonth()
      this.onClickSearch()
      this.buttonIndex = 2
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickCurrentMonth()
    },
    // 重置搜索条件
    resetSearchData () {
      this.gridId = ''
      this.gridName = ''
      this.planName = ''
      this.nameId = ''
      this.name = ''
      this.date = []
      this.fieldName = ''
      this.byFieldName = ''
      this.currentAttendanceTabIndex = 0
      this.currentLeaveTabIndex = 0
      this.currentOvertimeTabIndex = 0
      this.buttonIndex = null
    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentAttendanceTabIndex) {
            case 0:
              this.getAttendanceSummaryList()
              break;
            case 1:
              this.getAttendanceDetailList()
              break;
            case 2:
              this.getCorrectList()
              break;
            default:
              break;
          }
          break;
        case 1:

          break;
        case 2:

          break;
        default:
          break;
      }
    },
    // 出勤汇总列表
    getAttendanceSummaryList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        gridId: this.gridNameId,
        employeeId: this.nameId,
        startDate: this.date[0],
        endDate: this.date[1],
      }
      this.dateColumnInit()
      this.tableLoading = true
      this.$http.getRoadPlanAttendanceSummaryList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.attendanceSummaryData = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 初始化table日期列
    dateColumnInit () {
      let startDate = new Date(this.date[0])
      let endDate = new Date(this.date[1])
      let dateArr = []
      while (endDate >= startDate) {
        let year = startDate.getFullYear()
        let month = startDate.getMonth() + 1 < 10 ? '0' + (startDate.getMonth() + 1) : startDate.getMonth() + 1
        let day = startDate.getDate().toString().length == 1 ? "0" + startDate.getDate() : startDate.getDate()
        dateArr.push({
          title: month + '-' + day,
          key: year + '-' + month + '-' + day,
          width: 50,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let column = params.column
            let currentDateItem = this.getCurrentDateItem(column.key, row.attendanceCountDayVo)
            let content
            if (currentDateItem) {
              switch (currentDateItem.status) {
                case 0:
                  content = h('Icon', {
                    attrs: {
                      custom: 'i-icon icon-zhengque',
                      size: '26',
                      color: '#0eb36e'
                    }
                  })
                  break;
                case 1:
                  content = h('Icon', {
                    attrs: {
                      custom: 'i-icon icon-yi',
                      size: '26',
                      color: '#FD2323'
                    }
                  })
                  break;
                default:
                  break;
              }
            } else {
              content = h('span', '/')
            }
            return h('div', {
              style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }
            }, [content]);
          }
        })
        startDate.setDate(startDate.getDate() + 1)
      }
      this.columnsAttendanceSummary.splice(9, this.columnsAttendanceSummary.length - 9, ...dateArr)
    },
    // 获取当前日期item
    getCurrentDateItem (date, arr) {
      let str
      if (arr && arr.length != 0) {
        str = arr.find(item => item.date === date)
      }
      return str
    },
    // 出勤明细列表
    getAttendanceDetailList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        gridId: this.gridNameId,
        employeeId: this.nameId,
        startDate: this.date[0],
        endDate: this.date[1],
      }
      this.tableLoading = true
      this.$http.getRoadPlanAttendanceDetailList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          let maxIndex = 0
          res.result.list.forEach((item, index) => {
            if (item.attendanceDetailDayVoList.length > maxIndex) {
              maxIndex = index
            }
          })
          this.dateDetailColumnInit(res.result.list[maxIndex].attendanceDetailDayVoList)
          this.totalPage = res.result.total
          this.attendanceDetailData = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 初始化table 上下班列
    dateDetailColumnInit (arr) {
      let dateArr = []
      arr.forEach((item, index) => {
        let title
        let key
        for (const items in item) {
          if (items == 'gridName') {
            title = '电子围栏'
            key = 'gridName'
          } else if (items == 'workTime') {
            title = '上班时间'
            key = 'workTime'
          } else if (items == 'afterWorkTime') {
            title = '下班时间'
            key = 'afterWorkTime'
          }
          if (items != 'id' && items != 'gridId') {
            dateArr.push({
              title: title,
              key: key,
              width: 100,
              align: 'center',
              render: (h, params) => {
                let row = params.row
                let column = params.column
                let content
                if (row.attendanceDetailDayVoList[index]) {
                  return h('div', {
                    style: {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer'
                    },
                    on: {
                      click: () => {
                        this.onClickRoadCleanCorrect(row.attendanceDetailDayVoList[index])
                      }
                    }
                  }, row.attendanceDetailDayVoList[index][items]);
                } else {
                  if (items != 'gridName') {
                    return h('div', {
                      style: {
                        color: '#A8A8A8'
                      }
                    }, '无考勤计划');
                  } else {
                    return h('div', '');
                  }
                }
              }
            })
          }
        }
      })
      this.columnsAttendanceDetail.splice(4, this.columnsAttendanceDetail.length - 4, ...dateArr)
    },
    // 纠正记录列表
    getCorrectList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        gridId: this.gridNameId,
        employeeId: this.nameId,
        startDate: this.date[0],
        endDate: this.date[1],
      }
      this.tableLoading = true
      this.$http.getRoadPlanAttendanceCorrectList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.correctData = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 打卡纠正
    onClickRoadCleanCorrect (data) {
      this.roadCleanCorrectData = data
      this.roadCleanCorrectVisible = true
    },
    // 纠正记录的恢复
    onClickRecovery (row) {
      this.$Modal.confirm({
        title: '恢复',
        content: '确认恢复?',
        onOk: () => {
          let params = {
            employeeCorrectRecordId: row.id
          }
          this.$http.recoveryCorrectionRecord(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.getList()
            }
          })
        }
      })
    },
    // 点击出勤汇总人员姓名
    onClickAttendancePersonName (row) {
      this.nameId = row.employeeId
      this.name = row.employeeName
      this.currentAttendanceTabIndex = 1
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentAttendanceTabIndex) {
        case 0:
          data = {
            carNumid: this.carNumberId,
            deptid: this.departmentId,
            oilCardNum: this.oilCardNumber,
          }
          if (this.date[0]) {
            data.startTime = this.date[0]
            data.endTime = this.date[1]
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else {
            data.ids = []
          }
          break;
        case 1:
          data = {
            carNumid: this.carNumberId,
            deptid: this.departmentId,
            repairOddNumbers: this.repairOrder,
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else {
            data.ids = []
          }
          break;

        default:
          break;
      }
      return data
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-recovery-color td {
    color: #a8a8a8;
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    height: 160px;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }
    .table {
      margin-top: 16px;
    }
  }
}
</style>