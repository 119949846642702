<template>
  <Modal :value="visible"
         @on-visible-change="onChangeVisible"
         :title="title"
         width="600"
         class-name="vertical-center-modal">
    <div class="bounced">
      <div class="gwork-clocktime">
        <ul>
          <li>
            <span class="symbol">*</span>
            <span class="text">上班打卡时间:</span>
            <span class="time">
              <TimePicker v-model="workTime"
                          format="HH:mm"
                          placeholder="选择时间"
                          style="text-align:center"></TimePicker>
            </span>
          </li>
        </ul>
      </div>
      <div class="awork-clocktime">
        <ul>
          <li>
            <span class="symbol">*</span>
            <span class="text">下班打卡时间:</span>
            <span class="time">
              <TimePicker v-model="afterWorkTime"
                          format="HH:mm"
                          placeholder="选择时间"></TimePicker>
            </span>
          </li>
        </ul>
      </div>
      <div class="correct">
        <ul class="jiuzheng">
          <li class="text">纠正理由:</li>
          <li class="time">
            <Input v-model="remark"
                   placeholder="纠正理由" />
          </li>
          <li class="zi">15字以内</li>
        </ul>
      </div>

      <div class="effect">
        <Icon custom="i-icon icon-tishi1"
              size="16"
              color="#FF9B77"></Icon>
        <span> 纠正结果提交后次日生效</span>
      </div>
    </div>

    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: "visible",
    event: "onChange",
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: "打卡纠正",
    },
    data: Object,
  },
  data () {
    return {
      loading: false,
      workTime: '',
      afterWorkTime: '',
      remark: '',
      dataId: ''
    };
  },
  watch: {
    data (newVal) {
      if (newVal) {
        for (const i in newVal) {
          this[i] = newVal[i]
        }
        this.dataId = newVal.id
      }
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init () { },
    onClickConfirm () {
      let params = {
        employeePunchCardId: this.dataId,
        afterCorrectGotoWork: this.workTime,
        afterCorrectComeWork: this.afterWorkTime,
        correctReason: this.remark,
      }
      this.loading = true
      this.$http.correctionRecord(params).then((res) => {
        if (res.code == 200) {
          this.loading = false
          this.$emit('onChange', false)
          this.$Message.info(err.message)
        } else {
          this.loading = false
          this.$Message.info(err.message)
        }
        console.log(res)
      }).catch((err) => {
        this.loading = false
        this.$Message.info(err.message)
      });
    },
    onClickCancel () {
      this.$emit("onClickCancel");
      this.$emit("onChange", false);
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData();
        this.$emit("onChange", false);
        this.$emit("update:data", {});
      }
    },
    resetData () { },
  },
};
</script>

<style lang='scss' scoped>
.jiuzheng {
  display: flex;
  align-items: center;
}
.symbol {
  color: red;
}
.bounced {
  width: 100%;
  height: 100%;
  margin-top: 5px;
  margin-left: 20px;
}
.awork-clocktime {
  margin-top: 20px;
}
.correct {
  margin-top: 20px;
}
.text {
  margin-right: 10px;
}
.correct {
  padding: 0 30px;
}
.effect {
  color: #999;
  font-size: 5px;
  margin-top: 50px;
}
.zi {
  color: #999;
  font-size: 5px;
  padding-left: 10px;
}
</style>